<template>
	<div class="PurchaseDetail">
		<w-navTab titleText="详情"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container">
			<div class="header">
				<div class="header-swipt">
					<van-swipe @change="onChange" :autoplay="3000">
						<van-swipe-item v-for="(item,index) in detail.image_galleries"><img :src="item" alt=""></van-swipe-item>
						<template #indicator>
							<div class="custom-indicator">{{ current + 1 }}/{{length}}</div>
						</template>
					</van-swipe>
				</div>
				<div class="header-content">
					<div class="header-content-number">
						<span>￥</span>{{detail.price}}
					</div>
					<div class="header-content-title">
						{{detail.name}}
					</div>
				</div>
			</div>
			
			<div class="body">
				<div class="body-title">宝贝详情</div>
				<div class="body-content" v-html="detail.intro"></div>
			</div>
			<div class="footer" >
				<div class="footer-right" @click="$router.push({ name: 'PurchaseBuy',params:{id: productsId}})">立即购买</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Purchase from '@/api/purchase';
	import VantVendor from '@/vendor/vant';
	import LoadingPage from '@/components/LoadingPage';
	
	export default{
		name: 'PurchaseDetail',
		data() {
			return{
				current: 0,
				pictureList: [],
				productsId: "",
				detail: '',
				length: '',
				loadingPage: true,
			}
		},
		created() {
			this.productsId = this.$route.params.id
			this.getDetail()
		},
		methods:{
			getDetail(){
				Purchase.productsDetail({products_id: this.productsId})
					.then(result => {
						this.detail = result.data
						this.length = result.data.image_galleries.length
						this.loadingPage = false
					})
					.catch(error => {
						console.log(error)
					})
			},
			onChange(index) {
				this.current = index;
			},
		},
		components:{
			VantVendor,
			LoadingPage,
		}
	}
</script>

<style scoped lang="less">
	.PurchaseDetail{
		padding-bottom: 60px;
		box-sizing: border-box;
		.container{
			.header{
				.header-swipt {
					width: 375px;
					height: 375px;
					
					/deep/.van-swipe{
						height: 375px;
					}
					
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				
					.custom-indicator {
						position: absolute;
						right: 7px;
						bottom: 10px;
						padding: 3px 10px;
						font-size: 14px;
						color: #fff;
						background: rgba(0, 0, 0, 0.2);
						border-radius: 100px;
					}
				}
				
				.header-content{
					padding: 12px 16px 16px;
					box-sizing: border-box;
					background: #FFF;
					
					.header-content-number{
						color: #FF6969;
						font-size: 26px;
						font-weight: bold;
						line-height: 38px;
						
						span{
							font-size: 16px;
							line-height: 24px;
							margin-right: 2px;
						}
					}
					.header-content-title{
						color: #333;
						font-size: 18px;
						line-height: 25px;
						margin-top: 8px;
					}
				}
			}
			
			.body{
				.body-title{
					padding: 6px 0 5px;
					box-sizing: border-box;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #787878;
					font-size: 14px;
					line-height: 21px;
					
					&:before{
						content: '';
						display: inline-block;
						width: 28px;
						height: 1px;
						background: #BEBDC2;
						margin: 0 8px;
					}
					
					&:after{
						content: '';
						display: inline-block;
						width: 28px;
						height: 1px;
						background: #BEBDC2;
						margin: 0 8px;
					}
				}
				.body-content {
					padding: 16px;
					background: #FFF;
					overflow: hidden;
					box-sizing: border-box;
					color: #333;
					font-size: 18px;
					line-height: 25px;
					
					/deep/img {
						width: 100%;
						height: 100%;
						display: block;
						object-fit: cover;
						padding: 0;
					}
					/deep/p{
						padding: 0;
					}
				}
			}
		
			.footer{
				width: 100%;
				position: fixed;
				bottom: 0;
				left: 0;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 12px 16px;
				box-sizing: border-box;
				background: #fff;
				
				.footer-left{
					.footer-left-icon{
						width: 24px;
						height: 24px;
						margin: 0 auto;
						
						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
					.footer-left-text{
						color: #999;
						font-size: 12px;
						line-height: 18px;
					}
				}
				.footer-right{
					width: 100%;
					padding: 9px 0;
					box-sizing: border-box;
					border-radius: 70px;
					background: #37F;
					color: #FFF;
					text-align: center;
					font-size: 16px;
					font-weight: bold;
					line-height: 24px;
				}
			}
		}
	}
</style>