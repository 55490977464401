import Config from "@/vendor/config"
import Request from "@/api/request.js"

// 采购公司列表
const companiesList = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/purchase/companies/list', {params})
}

// 采购公司列表
const companiesDetail = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/purchase/companies/detail', {params})
}

// 采购商品列表
const productsList = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/purchase/products/list', {params})
}

// 采购商品详情
const productsDetail = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/purchase/products/detail', {params})
}

// 采购申请
const apply = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/purchase/apply', params)
}

export default {
	companiesList,
	companiesDetail,
	productsList,
	productsDetail,
	apply
}
